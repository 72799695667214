<template>
  <!-- begin::My Company users for TransporterAdmin & SenderAdmin roles -->
  <div class="gray-box pt-12">
    <div class="row">
      <div class="col-md-9 manager-subtitle pl-18" style="border: 0">
        {{ $t("MY_COMPANY.MY_USERS") }}
      </div>
      <div class="col-md-3 text-right pr-18">
        <button
          class="btn button-filter btn-pill btn-primary mb-3"
          @click="
            $router.push(
              currentUserRoleID === rolesList.ID_TRANSPORTER_ADMIN
                ? '/manager/drivers/new'
                : '/manager/employees/new'
            )
          "
        >
          <inline-svg src="/media/icons/add.svg" class="mr-3" />
          {{
            currentUserRoleID === rolesList.ID_TRANSPORTER_ADMIN
              ? $t("DRIVERS.TITLE_ADD")
              : $t("EMPLOYEES.TITLE_ADD")
          }}
        </button>
      </div>
    </div>

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ formatRoleName(item.RoleID) }}
          </td>
          <td>
            {{ item.Name }}
          </td>
          <td>
            {{ item.Email }}
          </td>
          <td>
            {{ item.Phone }}
          </td>
          <td
            v-if="
              item.RoleID == rolesList.ID_SENDER_EMPLOYEE ||
                item.RoleID == rolesList.ID_TRANSPORTER_DRIVER
            "
          >
            <span
              class="action-icon"
              @click="
                $router.push(
                  item.RoleID == rolesList.ID_SENDER_EMPLOYEE
                    ? '/manager/employees/edit/' + item.UserID
                    : '/manager/drivers/edit/' + item.UserID
                )
              "
            >
              <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
            </span>
            <span class="action-icon ml-4" @click="deleteStaff(item)">
              <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
            </span>
          </td>
          <td v-else>
            <span
              class="action-icon"
              @click="$router.push('/manager/myownprofile')"
            >
              <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="listMyStaffAndMe(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!-- end::My Company users for TransporterAdmin & SenderAdmin roles -->
</template>

<script>
import { mapGetters } from "vuex";

import Swal from "sweetalert2";

import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "MyCompanyUsers",
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"])
  },
  data() {
    return {
      TableHeaders: [
        { text: this.$t("MY_PROFILE.ROLE"), value: "RoleID" },
        { text: this.$t("MY_PROFILE.FULLNAME"), value: "Name" },
        { text: this.$t("MY_PROFILE.EMAIL"), value: "Email" },
        { text: this.$t("MY_PROFILE.PHONE"), value: "Phone" },
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
      ],
      options: {},
      SortBy: "RoleID",
      SortDesc: false,
      RoleOptions: [],
      RoleNames: [],
      ItemsList: [],
      ItemsSearching: true,
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },
  mounted() {
    this.listMyStaffAndMe(false);
  },
  methods: {
    listMyStaffAndMe(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      UserService.listMyStaffAndMe(
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.Users;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.listMyStaffAndMe(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.listMyStaffAndMe(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.listMyStaffAndMe(false);
    },

    changeItemsCurrentPage() {
      this.listMyStaffAndMe(true);
    },

    formatRoleName(roleID) {
      return this.$i18n.t("ROLE_NAMES_BY_ID." + roleID);
    },

    deleteStaff(item) {
      Swal.fire({
        title: this.$i18n.t("GENERIC.DELETE_ALERT") + "\n" + item.Name,
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          UserService.deleteStaff(item.UserID).then(statusCode => {
            if (statusCode >= 400) {
              this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              Swal.fire({
                text: this.$i18n.t("GENERIC.DELETE_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              this.listMyStaffAndMe(true);
            }
          });
        }
      });
    }
  }
};
</script>
