<template>
  <!-- begin::MyProfile -->
  <div v-if="isAuthenticated && loggedUserRoleID > 0">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/perfil-primary.svg"
      />
      <div>
        {{ $t("MY_PROFILE.TITLE") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <MyOwnProfileSuperadmin
      v-if="loggedUserRoleID === this.rolesList.ID_SUPERADMIN"
    />

    <MyOwnProfile
      v-if="loggedUserRoleID === this.rolesList.ID_OBSERVER"
      :embedded="false"
    />

    <MyCompanyTabsAdmin
      v-if="
        loggedUserRoleID === this.rolesList.ID_SENDER_ADMIN ||
          loggedUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN
      "
    />

    <MyCompanyTabsSenderEmployee
      v-if="loggedUserRoleID === this.rolesList.ID_SENDER_EMPLOYEE"
    />
  </div>
  <!-- end::MyProfile -->
</template>

<script>
import { mapGetters } from "vuex";
import MyOwnProfile from "@/view/pages/manager/myprofile/MyOwnProfile.vue";
import MyOwnProfileSuperadmin from "@/view/pages/manager/myprofile/MyOwnProfileSuperadmin.vue";
import MyCompanyTabsAdmin from "@/view/pages/manager/companies/admin/MyCompanyTabs.vue";
import MyCompanyTabsSenderEmployee from "@/view/pages/manager/companies/senderemployee/MyCompanyTabs.vue";

export default {
  name: "MyProfile",
  components: {
    MyOwnProfile,
    MyOwnProfileSuperadmin,
    MyCompanyTabsAdmin,
    MyCompanyTabsSenderEmployee
  },
  data() {
    return {
      loggedUserRoleID: 0
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"])
  },
  mounted() {
    // Set content by role
    this.loggedUserRoleID = this.currentUserRoleID;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setCompany") {
        this.loggedUserRoleID = state.auth.user.Company.RoleID;
      }
    });
  }
};
</script>
