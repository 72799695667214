<template>
  <!-- begin::My Company Conditions for TransporterAdmin role -->
  <div class="white-box shadow-box">
    <div class="search-info my-6 d-flex align-items-top">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      <p class="mb-0">
        {{ $t("MY_COMPANY.MY_CONDITIONS.INFO_1") }}
        <br />
        {{ $t("MY_COMPANY.MY_CONDITIONS.INFO_2") }}
      </p>
    </div>

    <v-form ref="formConditions" v-model="Valid" lazy-validation class="form">
      <div
        v-for="i in ConditionItems"
        :key="'Line_' + i"
        class="row form-group"
      >
        <label class="col-lg-12">
          {{ $t("MY_COMPANY.MY_CONDITIONS.CONDITION") + " " + i }}
        </label>
        <div
          v-for="aLang in LanguageList"
          :key="'Line_' + i + '_' + aLang.lang"
          class="col-lg-12 form-group mb-2"
        >
          <label>
            {{ aLang.name }}
          </label>
          <v-text-field
            class="form-control"
            v-model="Fields.Conditions[aLang.lang][i - 1]"
          />
        </div>
      </div>

      <div class="p-3">
        <button
          class="btn btn-pill button-filter btn-primary mb-3"
          @click.prevent="addConditionItem"
        >
          <inline-svg src="/media/icons/add.svg" class="mr-3" />
          {{ $t("MY_COMPANY.MY_CONDITIONS.ADD_CONDITION") }}
        </button>
      </div>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/')"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </v-form>
  </div>
  <!-- end::My Company Conditions for TransporterAdmin role -->
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import CompanyService from "@/core/services/api/v2/company.service";

export default {
  name: "MyCompanyConditions",

  data() {
    return {
      Valid: true,
      LanguageList: i18nService.languages,
      ConditionItems: 0,
      Fields: {
        Conditions: {}
      }
    };
  },

  mounted() {
    this.loadMyConditions();
  },

  methods: {
    loadMyConditions() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      CompanyService.getMyCompanyConditions().then(response => {
        if (response.Conditions) {
          this.Fields.Conditions = response.Conditions;

          // Count lines of Conditions on most-filled language
          this.LanguageList.forEach(aLang => {
            let langCode = aLang.lang;
            let langItems = response.Conditions[langCode]
              ? response.Conditions[langCode].length
              : 0;

            if (this.ConditionItems < langItems) {
              this.ConditionItems = langItems;
            }
          });
        }

        this.padConditionItems();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      });
    },

    padConditionItems() {
      // Ensure all languages have the same amount of Condition lines
      this.LanguageList.forEach(aLang => {
        let langCode = aLang.lang;
        if (!this.Fields.Conditions[langCode]) {
          this.Fields.Conditions[langCode] = [];
        }

        while (this.Fields.Conditions[langCode].length < this.ConditionItems) {
          this.Fields.Conditions[langCode].push("");
        }
      });
    },

    addConditionItem(e) {
      e.preventDefault();
      this.ConditionItems = this.ConditionItems + 1;
      this.padConditionItems();
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formConditions.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        CompanyService.updateMyCompanyConditions(this.Fields).then(
          statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          }
        );
      }
    }
  }
};
</script>
