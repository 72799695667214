<template>
  <!-- begin::MyCompany edit for TransporterAdmin & SenderAdmin Roles -->
  <div>
    <div class="white-box shadow-box">
      <!-- begin::Basic Company data -->
      <div class="manager-subtitle">
        {{ $t("COMPANIES.SUBTITLE_FORM_BASIC") }}
      </div>

      <v-form
        ref="formBasic"
        v-model="FormBasic.Valid"
        lazy-validation
        class="form"
      >
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.TRADE_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.TradeName"
              :placeholder="$t('COMPANIES.TRADE_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FISCAL_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.FiscalName"
              :placeholder="$t('COMPANIES.FISCAL_NAME_INFO')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FISCAL_ID") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.FiscalID"
              :placeholder="$t('COMPANIES.FISCAL_ID')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div
            v-if="FormBasic.Fields.CompanyTypeID == companyTypesList.ID_SENDER"
            class="form-group col-lg-4"
          >
            <label>
              {{ $t("COMPANIES.BRAND_NAME") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.BrandName"
              :placeholder="$t('COMPANIES.BRAND_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.ContactName"
              :placeholder="$t('COMPANIES.CONTACT_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_EMAIL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.ContactEmail"
              :placeholder="$t('COMPANIES.CONTACT_EMAIL')"
              required
              :rules="[rules.required, rules.email]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_PHONE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.ContactPhone"
              :placeholder="$t('COMPANIES.CONTACT_PHONE')"
              required
              :rules="[rules.required]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FULL_ADDRESS") }}
            </label>
            <VueGoogleAutocomplete
              ref="autocompleteAddress"
              id="autocompleteAddress"
              country="es"
              :fields="['address_components', 'geometry', 'id', 'name']"
              v-model="FormBasic.Fields.Street"
              :rules="[rules.required]"
              required
              classname="form-control"
              placeholder=""
              @placechanged="onAutocompletePlaceChanged"
              @inputChange="onAutocompleteInputChange"
            />
            <div
              v-if="!addressValid"
              class="v-text-field__details pt-2 pl-5 pb-0 m-0"
            >
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-2">
            <label>
              {{ $t("COMPANIES.POSTAL_CODE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.PostalCode"
              :placeholder="$t('COMPANIES.POSTAL_CODE')"
              required
              :rules="[rules.required]"
              @keyup="onPostalCodeChanged"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("COMPANIES.CITY") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.City"
              :placeholder="$t('COMPANIES.CITY')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("COMPANIES.PROVINCE") }}
            </label>
            <select
              class="form-control select-down"
              v-model="FormBasic.Fields.ProvinceID"
              required
            >
              <option
                v-for="p in FormBasic.ProvinceOptions"
                :key="p.ProvinceID"
                :value="p.ProvinceID"
                class="text-primary"
              >
                {{ p.Name }}
              </option>
            </select>
          </div>
        </div>

        <!-- begin::Payment data for Transporters -->
        <div
          v-if="
            FormBasic.Fields.CompanyTypeID == companyTypesList.ID_TRANSPORTER
          "
        >
          <div class="manager-subtitle">
            {{ $t("COMPANIES.PAYMENT_DATA.TITLE") }}
          </div>
          <p>
            {{ $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.INTERNAL_1") }}
            <br />
            {{ $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.INTERNAL_2") }}
          </p>

          <div class="row">
            <div class="form-group col-lg-4 mb-0">
              <label>
                {{
                  $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.PAYMENT_PERCENT_ADMIN")
                }}
              </label>
              <v-text-field
                class="form-control shortInput"
                v-model="FormBasic.Fields.PaymentPercent"
                type="number"
                readonly
              />
            </div>

            <div class="form-group col-lg-4 mb-0">
              <label>
                {{
                  $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.MULTISAFEPAY_MERCHANT")
                }}
              </label>
              <v-text-field
                class="form-control"
                v-model="FormBasic.Fields.MultisafepayMerchantID"
                readonly
              />
            </div>
          </div>

          <p class="mt-6">
            {{ $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.EXTERNAL_1") }}
            <br />
            {{ $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.EXTERNAL_2") }}
          </p>

          <div class="row">
            <div class="form-group col-lg-4 mb-0">
              <label>
                {{ $t("COMPANIES.PAYMENT_DATA.BANK_NAME") }}
              </label>
              <v-text-field
                class="form-control"
                v-model="FormBasic.Fields.BankName"
              />
            </div>

            <div class="form-group col-lg-4 mb-0">
              <label>
                {{ $t("COMPANIES.PAYMENT_DATA.IBAN") }}
              </label>
              <v-text-field
                class="form-control"
                v-model="FormBasic.Fields.Iban"
              />
            </div>

            <div class="form-group col-lg-4 mb-0">
              <label>
                {{ $t("COMPANIES.PAYMENT_DATA.SWIFT") }}
              </label>
              <v-text-field
                class="form-control"
                v-model="FormBasic.Fields.Swift"
              />
            </div>
          </div>
        </div>
        <!-- end::Payment data for Transporters -->

        <!-- As of 2022-04, don't show Payment data for SendersPremium -->
      </v-form>
      <!-- end::Basic Company data -->

      <!-- begin::CurrentSubscription data -->
      <div class="manager-subtitle mt-6">
        {{ $t("MY_COMPANY.EDIT_CURRENT_SUBSCRIPTION_SUBTITLE") }}
      </div>
      <div class="row">
        <div class="col-lg-6 primary-box">
          <div class="primary-box-header">
            <div class="row">
              <div class="col-lg-6 p-3">
                <h4 class="mb-0 ml-6">
                  <strong>{{ CurrentSubscriptionData.Name }}</strong>
                </h4>
              </div>
              <div class="col-lg-6 p-3 text-right">
                <h4
                  class="mb-0 pt-0 pr-6"
                  v-html="
                    formatSubscriptionPrice(
                      CurrentSubscriptionData.PricePerMonth,
                      CurrentSubscriptionData.Months
                    )
                  "
                ></h4>
              </div>
            </div>
          </div>
          <div class="primary-box-body">
            <ul>
              <li
                v-for="(descLine, d) in CurrentSubscriptionData.Description"
                :key="d"
              >
                {{ descLine }}
              </li>
            </ul>
            <div v-if="CurrentSubscriptionData.PricePerMonth > 0">
              <strong>{{ $t("COMPANIES.SUBSCRIPTION_STATUS") }}:</strong>
              {{ CurrentSubscriptionData.Status }}
              <br />
              <strong>{{ $t("COMPANIES.RENEWAL_DATE") }}:</strong>
              {{ CurrentSubscriptionData.ActiveUntil }}
            </div>
          </div>
        </div>
        <div class="col-lg-6 pl-9">
          <div v-if="CanChangeSubscription" class="mb-9">
            <button
              class="btn btn-pill btn-tertiary button-form"
              @click="$router.push('/manager/mysubscription')"
            >
              {{ $t("MY_COMPANY.CHANGE_SUBSCRIPTION") }}
            </button>
          </div>
        </div>
      </div>
      <!-- end::CurrentSubscription data -->

      <!-- begin::Action buttons -->
      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/')"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!FormBasic.Valid || !addressValid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
      <!-- end::Action buttons -->
    </div>
  </div>
  <!-- end::MyCompany edit for TransporterAdmin & SenderAdmin Roles -->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VueGoogleAutocomplete from "vue-google-autocomplete";

import CompanyService from "@/core/services/api/v2/company.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import SubscriptionService from "@/core/services/api/v2/subscription.service";
import SubscriptionStatusService from "@/core/services/api/v2/subscriptionstatus.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

export default {
  name: "MyCompanyFormAdmin",
  components: {
    VueGoogleAutocomplete
  },

  computed: {
    ...mapGetters(["companyTypesList", "currentUserCompanyIsPremium"]),
    addressValid() {
      return this.FormBasic.Fields.Street.length > 0;
    }
  },

  data() {
    return {
      FormBasic: {
        Valid: true,
        Fields: {
          Code: "",
          TradeName: "",
          FiscalName: "",
          FiscalID: "",
          BrandName: "",
          ContactName: "",
          ContactPhone: "",
          ContactEmail: "",
          Street: "",
          PostalCode: "",
          City: "",
          ProvinceID: 0,
          CompanyTypeID: 0,
          CompanyClassID: 0,
          MultisafepayMerchantID: "",
          PaymentPercent: 0,
          BankName: "",
          Iban: "",
          Swift: "",
          Active: 0
        },
        ProvinceOptions: []
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      },
      CurrentSubscriptionData: {
        Name: "",
        Description: [],
        PricePerMonth: -1,
        Months: 0,
        ActiveUntil: "",
        Status: ""
      },
      CanChangeSubscription: false,
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY"
    };
  },

  created() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    this.DateFormatLang =
      userLang === "en" ? this.DateFormatEN : this.DateFormatES;

    this.loadProvinceOptions();
  },

  mounted() {
    this.loadMyCompanyData();
  },

  methods: {
    loadProvinceOptions() {
      ProvinceService.listAll().then(response => {
        this.FormBasic.ProvinceOptions = response;
      });
    },

    loadMyCompanyData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      // 1. Basic data
      CompanyService.getMyCompanyProfile().then(response1 => {
        if (response1 === 404) {
          this.$router.push("/manager/");
        } else {
          for (var f1 in this.FormBasic.Fields) {
            this.FormBasic.Fields[f1] = response1[f1];
          }

          // 2. Subscription data: current vs alternatives (if any)
          SubscriptionStatusService.listAll().then(statusesList => {
            SubscriptionTypeService.listAll(response1.CompanyTypeID).then(
              typesList => {
                SubscriptionService.getSubscriptionProfileMyCompany().then(
                  response2 => {
                    if (response2 !== 404) {
                      this.CurrentSubscriptionData.PricePerMonth =
                        response2.PricePerMonth;
                      this.CurrentSubscriptionData.Months = response2.Months;
                      this.CurrentSubscriptionData.ActiveUntil = this.formatDate(
                        response2.ActiveUntil
                      );

                      // Get its status name
                      statusesList.forEach(ss => {
                        if (
                          ss.SubscriptionStatusID ===
                          response2.SubscriptionStatusID
                        ) {
                          this.CurrentSubscriptionData.Status = ss.Name;
                        }
                      });

                      // Get its type name & description
                      typesList.forEach(st => {
                        if (
                          st.SubscriptionTypeID === response2.SubscriptionTypeID
                        ) {
                          this.CurrentSubscriptionData.Name = st.Name;
                          this.CurrentSubscriptionData.Description =
                            st.Description;
                        }
                      });

                      this.CanChangeSubscription = typesList.length > 1;
                    }

                    // Remove page loader
                    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                  }
                );
              }
            );
          });
        }
      });
    },

    onAutocompletePlaceChanged(addressData) {
      this.FormBasic.Fields.Street =
        addressData.route +
        (addressData.street_number ? " " + addressData.street_number : "");

      this.FormBasic.Fields.PostalCode = addressData.postal_code;
      this.FormBasic.Fields.City = addressData.locality;
      this.onPostalCodeChanged();

      // Clean extra values from Street box
      this.$refs.autocompleteAddress.$refs.autocomplete.value = this.FormBasic.Fields.Street;
    },

    onAutocompleteInputChange(e) {
      if (e.newVal.trim().length === 0) {
        this.FormBasic.Fields.Street = "";
      }
    },

    onPostalCodeChanged() {
      // Convert PostalCode to an item in our ProvinceID selector
      // N.B: Province is CRITICAL because it sets the fare,
      // but Google doesn't give it,
      // that's why we have to guess it via PostalCode...
      // and why we restrict VueGoogleAutocomplete component to country="es"
      let newPostalCode = parseInt(this.FormBasic.Fields.PostalCode);
      let newProvinceID = 1;

      for (let p = 0; p < this.FormBasic.ProvinceOptions.length; p++) {
        let maybeProvince = this.FormBasic.ProvinceOptions[p];
        let pcStart = parseInt(maybeProvince.PostalCodeStart);
        let pcEnd = parseInt(maybeProvince.PostalCodeEnd);
        if (newPostalCode >= pcStart && newPostalCode <= pcEnd) {
          newProvinceID = maybeProvince.ProvinceID;
          break;
        }
      }

      this.FormBasic.Fields.ProvinceID = newProvinceID;
    },

    formatSubscriptionPrice(pricePerMonth, months) {
      let price = parseFloat(pricePerMonth);

      if (price < 0) {
        return "";
      } else if (price === 0) {
        return "<strong>" + this.$t("GENERIC.PRICE_FREE") + "</strong>";
      } else {
        price = price * months;
        return (
          "<strong>" +
          price.toFixed(2).replace(".", ",") +
          " €</strong> / " +
          this.$tc("SUBSCRIPTION_TYPES.PER_MONTHS", months, { count: months })
        );
      }
    },

    formatDate(date) {
      if (date && date.length > 0) {
        return moment(date).format(this.DateFormatLang);
      } else {
        return "-";
      }
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.FormBasic.Valid = false;

        // Send update request:
        CompanyService.updateMyCompanyProfile(this.FormBasic.Fields).then(
          statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("COMPANIES.SAVE_ERROR_FORM_BASIC"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("COMPANIES.SAVE_OK_FORM_BASIC"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.FormBasic.Valid = true;
          }
        );
      }
    }
  }
};
</script>
