<template>
  <!-- begin::My Company tabs for TransporterAdmin & SenderAdmin Roles -->
  <div v-if="isAuthenticated">
    <div v-if="TabList.length === 1">
      <MyCompanyFormAdmin />
    </div>

    <v-tabs v-else v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab grow v-for="t in TabList" :key="t.Position" :href="'#' + t.Name">
        {{ t.Title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item v-for="t in TabList" :key="t.Position" :value="t.Name">
        <div v-if="t.Name === 'basic'">
          <MyCompanyFormAdmin />

          <MyCompanyUsers />
        </div>

        <div v-else-if="t.Name === 'observers'">
          <UsersSenderIndex />
        </div>

        <div v-else-if="t.Name === 'conditions'">
          <MyCompanyConditions />
        </div>

        <div v-else-if="t.Name === 'mysenders'">
          <MySendersIndex />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- begin::My Company tabs for TransporterAdmin & SenderAdmin Roles -->
</template>

<script>
import { mapGetters } from "vuex";
import MyCompanyFormAdmin from "@/view/pages/manager/companies/admin/MyCompanyForm.vue";
import MyCompanyConditions from "@/view/pages/manager/companies/admin/MyCompanyConditions.vue";
import MySendersIndex from "@/view/pages/manager/companies/transporter/MySendersIndex.vue";
import MyCompanyUsers from "@/view/pages/manager/companies/admin/MyCompanyUsers.vue";
import UsersSenderIndex from "@/view/pages/manager/users/sender/observers/Index.vue";

export default {
  name: "MyCompanyTabsAdmin",
  components: {
    MyCompanyFormAdmin,
    MyCompanyConditions,
    MySendersIndex,
    MyCompanyUsers,
    UsersSenderIndex
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    }
  },
  data() {
    return {
      TabList: []
    };
  },
  mounted() {
    if (this.currentUserRoleID === this.rolesList.ID_SENDER_ADMIN) {
      this.TabList = [
        {
          Position: 0,
          Name: "basic",
          Title: this.$i18n.t("MY_COMPANY.CONFIG")
        },
        {
          Position: 1,
          Name: "observers",
          Title: this.$i18n.t("MY_COMPANY.MY_OBSERVERS")
        }
      ];
    } else if (this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN) {
      this.TabList = [
        {
          Position: 0,
          Name: "basic",
          Title: this.$i18n.t("MY_COMPANY.ACCOUNT_DATA")
        },
        {
          Position: 1,
          Name: "conditions",
          Title: this.$i18n.t("MY_COMPANY.MY_CONDITIONS.TITLE")
        },
        {
          Position: 2,
          Name: "mysenders",
          Title: this.$i18n.t("MY_COMPANY.MY_SENDERS")
        }
      ];
    }
  }
};
</script>
