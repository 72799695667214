<template>
  <!-- begin::MyOwnProfile for Superadmin Role -->
  <div v-if="isAuthenticated">
    <div class="white-box shadow-box">
      <div class="row">
        <div class="manager-subtitle">
          {{ $t("MY_PROFILE.SUBTITLE") }}
        </div>
      </div>

      <v-form
        class="form"
        ref="formMyOwnProfile"
        v-model="Valid"
        lazy-validation
      >
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.EMAIL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_CURRENT") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordCurrent"
              :placeholder="$t('MY_PROFILE.PASSWORD_ONLY_TO_CHANGE')"
              :rules="[rules.requiredIfPasswordNew]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordNew"
              :placeholder="$t('MY_PROFILE.PASSWORD_NEW')"
              :rules="[rules.emptyOrMin6]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW_CONFIRM") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="Fields.PasswordConfirm"
              :placeholder="$t('MY_PROFILE.PASSWORD_NEW_CONFIRM')"
              :rules="[rules.confirm]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.FULLNAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name"
              :placeholder="$t('MY_PROFILE.FULLNAME')"
              :rules="[rules.required]"
              required
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PHONE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Phone"
              :placeholder="$t('MY_PROFILE.PHONE')"
              :rules="[rules.required]"
              required
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row button-row">
          <button
            class="btn btn-pill btn-white button-form button-form-primary mr-9"
            @click="$router.push('/manager/home')"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>

          <button
            ref="formMyOwnProfileSubmit"
            class="btn btn-pill btn-tertiary button-form"
            :disabled="!Valid"
            @click="onSubmitProfile"
          >
            {{ $t("GENERIC.SAVE") }}
          </button>
        </div>
      </v-form>
    </div>
  </div>
  <!-- end::MyOwnProfile for Superadmin Role -->
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { CHANGE_NAME } from "@/core/services/store/auth.module";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "MyOwnProfileSuperadmin",
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  data() {
    return {
      Valid: true,
      Fields: {
        Email: "",
        Name: "",
        Phone: "",
        PasswordCurrent: "",
        PasswordNew: "",
        PasswordConfirm: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        emptyOrMin6: value => {
          if (!value || value.length === 0 || value.length >= 6) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.MIN_LENGTH_FIELD") + " 6";
          }
        },
        confirm: value =>
          value === this.Fields.PasswordNew ||
          this.$i18n.t("MY_PROFILE.PASSWORD_MISMATCH"),
        requiredIfPasswordNew: value => {
          if (
            !this.Fields.PasswordNew ||
            this.Fields.PasswordNew.length === 0 ||
            !!value
          ) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.REQUIRED_FIELD");
          }
        }
      }
    };
  },
  created() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    UserService.getCurrentUserProfile().then(response => {
      this.Fields = response;

      // Remove page loader
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    });
  },
  methods: {
    onSubmitProfile(e) {
      e.preventDefault();

      if (this.$refs.formMyOwnProfile.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send update request
        UserService.updateCurrentUserProfile(this.Fields).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 412) {
              whyError = this.$i18n.t("MY_PROFILE.ERROR_412");
            } else if (statusCode === 428) {
              whyError = this.$i18n.t("MY_PROFILE.ERROR_428");
            } else if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });
            this.$store.dispatch(CHANGE_NAME, this.Fields.Name);
          }

          // Reset submit button
          this.Valid = true;
          this.Fields.PasswordCurrent = "";
          this.Fields.PasswordNew = "";
          this.Fields.PasswordConfirm = "";
        });
      }
    }
  }
};
</script>
