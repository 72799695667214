<template>
  <!-- begin::MyCompany edit for SenderEmployee Role -->
  <div>
    <div class="white-box shadow-box">
      <!-- begin::MyProfile data -->
      <MyOwnProfile :embedded="true"></MyOwnProfile>
      <!-- end::MyProfile data -->

      <!-- begin::Basic Company data -->
      <div class="manager-subtitle">
        {{ $t("COMPANIES.SUBTITLE_FORM_BASIC") }}
      </div>

      <div class="row">
        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.TRADE_NAME") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.TradeName"
            :placeholder="$t('COMPANIES.TRADE_NAME')"
            readonly
          />
        </div>

        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.FISCAL_NAME") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.FiscalName"
            :placeholder="$t('COMPANIES.FISCAL_NAME_INFO')"
            readonly
          />
        </div>

        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.FISCAL_ID") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.FiscalID"
            :placeholder="$t('COMPANIES.FISCAL_ID')"
            readonly
          />
        </div>

        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.BRAND_NAME") }}
            <span class="font-weight-normal">
              ({{ $t("GENERIC.OPTIONAL") }})
            </span>
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.BrandName"
            :placeholder="$t('COMPANIES.BRAND_NAME')"
            readonly
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.CONTACT_NAME") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.ContactName"
            :placeholder="$t('COMPANIES.CONTACT_NAME')"
            readonly
          />
        </div>

        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.CONTACT_EMAIL") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.ContactEmail"
            :placeholder="$t('COMPANIES.CONTACT_EMAIL')"
            readonly
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/contact-gray.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.CONTACT_PHONE") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.ContactPhone"
            :placeholder="$t('COMPANIES.CONTACT_PHONE')"
            readonly
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/phone-gray.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="form-group col-lg-4">
          <label>
            {{ $t("COMPANIES.FULL_ADDRESS") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.Street"
            :placeholder="$t('COMPANIES.FULL_ADDRESS')"
            readonly
          />
        </div>

        <div class="form-group col-lg-2">
          <label>
            {{ $t("COMPANIES.POSTAL_CODE") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.PostalCode"
            :placeholder="$t('COMPANIES.POSTAL_CODE')"
            readonly
          />
        </div>

        <div class="form-group col-lg-3">
          <label>
            {{ $t("COMPANIES.CITY") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.City"
            :placeholder="$t('COMPANIES.CITY')"
            readonly
          />
        </div>

        <div class="form-group col-lg-3">
          <label>
            {{ $t("COMPANIES.PROVINCE") }}
          </label>
          <v-text-field
            class="form-control"
            v-model="CompanyData.ProvinceName"
            :placeholder="$t('COMPANIES.PROVINCE')"
            readonly
          />
        </div>
      </div>
      <!-- end::Basic Company data -->

      <!-- begin::CurrentSubscription data -->
      <div class="manager-subtitle">
        {{ $t("MY_COMPANY.EDIT_CURRENT_SUBSCRIPTION_SUBTITLE") }}
      </div>
      <div class="row">
        <div class="col-lg-6 primary-box">
          <div class="primary-box-header">
            <div class="row">
              <div class="col-lg-6 p-3">
                <h4 class="mb-0 ml-6">
                  <strong>{{ CurrentSubscriptionData.Name }}</strong>
                </h4>
              </div>
              <div class="col-lg-6 p-3 text-right">
                <h4
                  class="mb-0 pr-6"
                  v-html="
                    formatSubscriptionPrice(
                      CurrentSubscriptionData.PricePerMonth,
                      CurrentSubscriptionData.Months
                    )
                  "
                ></h4>
              </div>
            </div>
          </div>
          <div class="primary-box-body">
            <ul>
              <li
                v-for="(descLine, d) in CurrentSubscriptionData.Description"
                :key="d"
              >
                {{ descLine }}
              </li>
            </ul>
            <div v-if="CurrentSubscriptionData.PricePerMonth > 0">
              <strong>{{ $t("COMPANIES.SUBSCRIPTION_STATUS") }}:</strong>
              {{ CurrentSubscriptionData.Status }}
              <br />
              <strong>{{ $t("COMPANIES.RENEWAL_DATE") }}:</strong>
              {{ CurrentSubscriptionData.ActiveUntil }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          &nbsp;
        </div>
      </div>
      <!-- end::CurrentSubscription data -->

      <!-- begin::Action buttons -->
      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>
      </div>
      <!-- end::Action buttons -->
    </div>
  </div>
  <!-- end::Companies edit -->
</template>

<script>
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import CompanyService from "@/core/services/api/v2/company.service";
import SubscriptionService from "@/core/services/api/v2/subscription.service";
import SubscriptionStatusService from "@/core/services/api/v2/subscriptionstatus.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

import MyOwnProfile from "@/view/pages/manager/myprofile/MyOwnProfile.vue";

export default {
  name: "MyCompanyFormSenderEmployee",
  components: {
    MyOwnProfile
  },

  data() {
    return {
      CompanyData: {
        CompanyTypeID: 0,
        Code: "",
        TradeName: "",
        FiscalName: "",
        FiscalID: "",
        BrandName: "",
        ContactName: "",
        ContactPhone: "",
        ContactEmail: "",
        Street: "",
        PostalCode: "",
        City: "",
        ProvinceName: "",
        CompanyClassID: 0
      },
      CurrentSubscriptionData: {
        Name: "",
        Description: [],
        PricePerMonth: -1,
        Months: 0,
        ActiveUntil: "",
        Status: ""
      },
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY"
    };
  },

  mounted() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    this.DateFormatLang =
      userLang === "en" ? this.DateFormatEN : this.DateFormatES;

    this.loadMyCompanyData();
  },

  methods: {
    loadMyCompanyData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      // 1. Basic data
      CompanyService.getMyCompanyProfile().then(response1 => {
        if (response1 === 404) {
          this.$router.push("/manager/");
        } else {
          for (var f1 in this.CompanyData) {
            this.CompanyData[f1] = response1[f1];
          }

          // 2. Current subscription data
          SubscriptionStatusService.listAll().then(statusesList => {
            SubscriptionTypeService.listAll(
              this.CompanyData.CompanyTypeID
            ).then(typesList => {
              SubscriptionService.getSubscriptionProfileMyCompany().then(
                response2 => {
                  if (response2 !== 404) {
                    this.CurrentSubscriptionData.PricePerMonth =
                      response2.PricePerMonth;
                    this.CurrentSubscriptionData.Months = response2.Months;
                    this.CurrentSubscriptionData.ActiveUntil = this.formatDate(
                      response2.ActiveUntil
                    );

                    // Get its status name
                    statusesList.forEach(ss => {
                      if (
                        ss.SubscriptionStatusID ===
                        response2.SubscriptionStatusID
                      ) {
                        this.CurrentSubscriptionData.Status = ss.Name;
                      }
                    });

                    // Get its type name & description
                    typesList.forEach(st => {
                      if (
                        st.SubscriptionTypeID === response2.SubscriptionTypeID
                      ) {
                        this.CurrentSubscriptionData.Name = st.Name;
                        this.CurrentSubscriptionData.Description =
                          st.Description;
                      }
                    });
                  }

                  // Remove page loader
                  this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                }
              );
            });
          });
        }
      });
    },

    formatSubscriptionPrice(pricePerMonth, months) {
      let price = parseFloat(pricePerMonth);
      if (price < 0) {
        return "";
      } else if (price === 0) {
        return "<strong>" + this.$t("GENERIC.PRICE_FREE") + "</strong>";
      } else {
        price = price * months;
        return (
          "<strong>" +
          price.toFixed(2).replace(".", ",") +
          " €</strong> / " +
          this.$tc("SUBSCRIPTION_TYPES.PER_MONTHS", months, { count: months })
        );
      }
    },

    formatDate(date) {
      if (date && date.length > 0) {
        return moment(date).format(this.DateFormatLang);
      } else {
        return "-";
      }
    }
  }
};
</script>
