import ApiService from "@/core/services/api/v2/api.service";

const SUBSCRIPTION_STATUS_LIST_KEY = "SubscriptionStatusList";

const SubscriptionStatusService = {
  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(
        SUBSCRIPTION_STATUS_LIST_KEY
      );

      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("subscriptionStatus")
          .then(response => {
            let newList = response.data.response.SubscriptionStatuses;

            window.sessionStorage.setItem(
              SUBSCRIPTION_STATUS_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  }
};

export default SubscriptionStatusService;
