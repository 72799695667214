<template>
  <!-- begin::My Company tabs for SenderEmployee Role -->
  <div v-if="isAuthenticated">
    <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab grow v-for="t in TabList" :key="t.Position" :href="'#' + t.Name">
        {{ t.Title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item v-for="t in TabList" :key="t.Position" :value="t.Name">
        <div v-if="t.Name === 'basic'">
          <MyCompanyFormSenderEmployee />
        </div>

        <div v-else-if="t.Name === 'observers'">
          <UsersSenderIndex />
        </div>

        <div
          v-else
          class="white-box shadow-box p-12"
          title="Pendiente de definir"
          style="height: 50vh"
        >
          TBD...
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- begin::My Company tabs for SenderEmployee Role -->
</template>

<script>
import { mapGetters } from "vuex";
import MyCompanyFormSenderEmployee from "@/view/pages/manager/companies/senderemployee/MyCompanyForm.vue";
import UsersSenderIndex from "@/view/pages/manager/users/sender/observers/Index.vue";

export default {
  name: "MyCompanyTabsSenderEmployee",
  components: {
    MyCompanyFormSenderEmployee,
    UsersSenderIndex
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    }
  },
  data() {
    return {
      TabList: []
    };
  },
  mounted() {
    if (this.currentUserRoleID === this.rolesList.ID_SENDER_EMPLOYEE) {
      this.TabList = [
        {
          Position: 0,
          Name: "basic",
          Title: this.$i18n.t("MY_COMPANY.CONFIG")
        },
        {
          Position: 1,
          Name: "observers",
          Title: this.$i18n.t("MY_COMPANY.MY_OBSERVERS")
        },
        {
          Position: 2,
          Name: "transporters",
          Title: this.$i18n.t("MY_COMPANY.MY_TRANSPORTERS")
        }
      ];
    }
  }
};
</script>
