var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gray-box pt-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9 manager-subtitle pl-18",staticStyle:{"border":"0"}},[_vm._v(" "+_vm._s(_vm.$t("MY_COMPANY.MY_USERS"))+" ")]),_c('div',{staticClass:"col-md-3 text-right pr-18"},[_c('button',{staticClass:"btn button-filter btn-pill btn-primary mb-3",on:{"click":function($event){return _vm.$router.push(
            _vm.currentUserRoleID === _vm.rolesList.ID_TRANSPORTER_ADMIN
              ? '/manager/drivers/new'
              : '/manager/employees/new'
          )}}},[_c('inline-svg',{staticClass:"mr-3",attrs:{"src":"/media/icons/add.svg"}}),_vm._v(" "+_vm._s(_vm.currentUserRoleID === _vm.rolesList.ID_TRANSPORTER_ADMIN ? _vm.$t("DRIVERS.TITLE_ADD") : _vm.$t("EMPLOYEES.TITLE_ADD"))+" ")],1)])]),(_vm.ItemsSearching)?_c('div',{staticClass:"search-info"},[_c('inline-svg',{staticClass:"mx-3",attrs:{"src":"/media/icons/help-alt-5-success.svg"}}),_vm._v(" "+_vm._s(_vm.$t("GENERIC.SEARCHING"))+"... ")],1):_vm._e(),(_vm.ItemsList.length > 0)?_c('v-data-table',{staticClass:"white-table shadow-box",attrs:{"headers":_vm.TableHeaders,"items":_vm.ItemsList,"items-per-page":_vm.ItemsPerPage,"options":_vm.options,"server-items-length":_vm.ItemsTotal,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortDesc},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.formatRoleName(item.RoleID))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.Name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.Email)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.Phone)+" ")]),(
            item.RoleID == _vm.rolesList.ID_SENDER_EMPLOYEE ||
              item.RoleID == _vm.rolesList.ID_TRANSPORTER_DRIVER
          )?_c('td',[_c('span',{staticClass:"action-icon",on:{"click":function($event){return _vm.$router.push(
                item.RoleID == _vm.rolesList.ID_SENDER_EMPLOYEE
                  ? '/manager/employees/edit/' + item.UserID
                  : '/manager/drivers/edit/' + item.UserID
              )}}},[_c('inline-svg',{attrs:{"src":"/media/icons/edit-alt-2-primary.svg"}})],1),_c('span',{staticClass:"action-icon ml-4",on:{"click":function($event){return _vm.deleteStaff(item)}}},[_c('inline-svg',{attrs:{"src":"/media/icons/delete-alt-2-primary.svg"}})],1)]):_c('td',[_c('span',{staticClass:"action-icon",on:{"click":function($event){return _vm.$router.push('/manager/myownprofile')}}},[_c('inline-svg',{attrs:{"src":"/media/icons/edit-alt-2-primary.svg"}})],1)])])]}}],null,false,1267327374)}):_vm._e(),(!_vm.ItemsSearching && _vm.ItemsList.length > 0)?_c('div',{staticClass:"p-0 row"},[_c('div',{staticClass:"p-4 d-flex justify-content-between align-items-center flex-wrap w-100"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("GENERIC.PAGINATION_SHOW", { from: _vm.ItemsFrom, to: _vm.ItemsTo, total: _vm.ItemsTotal }))+" ")]),_c('v-pagination',{attrs:{"length":_vm.ItemsPages,"previous-aria-label":_vm.$t('GENERIC.PREVIOUS'),"next-aria-label":_vm.$t('GENERIC.NEXT')},on:{"input":function($event){return _vm.listMyStaffAndMe(true)}},model:{value:(_vm.ItemsCurrentPage),callback:function ($$v) {_vm.ItemsCurrentPage=$$v},expression:"ItemsCurrentPage"}}),_c('div',{staticClass:"d-flex align-items-baseline py-3"},[_c('v-select',{staticClass:"form-control form-control-sm mr-0 border-0 bg-light",staticStyle:{"max-width":"5em"},attrs:{"single-line":"","items":_vm.ItemsPerPageOptions},on:{"change":_vm.updateItemsPerPage},model:{value:(_vm.ItemsPerPage),callback:function ($$v) {_vm.ItemsPerPage=$$v},expression:"ItemsPerPage"}}),_c('span',[_vm._v(_vm._s(_vm.$t("GENERIC.PAGINATION_MENU")))])],1)],1)]):_vm._e(),(!_vm.ItemsSearching && _vm.ItemsList.length < 1)?_c('div',{staticClass:"search-info"},[_c('inline-svg',{staticClass:"mx-3",attrs:{"src":"/media/icons/help-alt-5-success.svg"}}),_vm._v(" "+_vm._s(_vm.$t("GENERIC.NO_RESULTS"))+" ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }